import React from "react"
import Link from "gatsby-link"
import ArrowRight from "./arrow-right"
import { sectionAbout, content, name, brandDesigner, text, link } from "./about.module.scss"

export default function About(props) {
    const texts = {
        "pl":
        {
            "title": "O autorze",
            "text": `<p>Dwukrotnie wyróżniony przez Adobe. Autor setek opakowań produktowych.</p>
        <p>Twórca dziesiątek identyfikacji wizualnych.</p>
        <p>Zwycięzca międzynarodowego konkursu TEN by Fotolia.</p>`,
            "linkUrl": "/autor",
            "linkLabel": "Poznaj"
        },
        "en":
        {
            "title": "About",
            "text": `<p>Awarded by Adobe twice. Author of hundreds of product packaging designs.</p>
        <p>Creator of dozens of visual identifications.</p>
        <p>Winner of the international TEN competition by Fotolia.</p>`,
            "linkUrl": "/author",
            "linkLabel": "Learn more"
        }
    }
    const textContent = texts[props.lang]
    return (
        <div className={sectionAbout}>
            <div className={content}>
                <h1 className={name}>Sebastian Powszuk</h1>
                <h2 className={brandDesigner}>Brand <span>Designer</span></h2>
                <div className={text} dangerouslySetInnerHTML={{ __html: textContent.text }} />
                <p className={link}>
                    <Link to={textContent.linkUrl}>
                        <span>{textContent.linkLabel}</span><ArrowRight />
                    </Link>
                </p>
            </div>
        </div>
    )
}
