// extracted by mini-css-extract-plugin
export var section = "index-module--section--Dyjl7";
export var sectionStart = "index-module--section-start--3-EZu";
export var sectionStart__nav = "index-module--section-start__nav--3F780";
export var sectionStart__arrowDown = "index-module--section-start__arrow-down--1Juyz";
export var sectionContact = "index-module--section-contact--1xokt";
export var space = "index-module--space--16uZ2";
export var comet = "index-module--comet--kUl7c";
export var comet1 = "index-module--comet1--3vfFA";
export var comet2 = "index-module--comet2--2n7fD";
export var arrowDown = "index-module--arrow-down--3Oygv";