import React from "react"
import { container, comets, comet, photo } from "./comets.module.scss"

const isBrowser = typeof window !== "undefined"

const Comets = () => {
    if (isBrowser) {
        const showUniverse = window.innerWidth > 1024 ? true : false;
        let items = [];
        const centerX = window.innerWidth/2;
        const centerY = window.innerHeight/2;

        if(showUniverse) {
            setupItems();
            document.body.addEventListener("mousemove", move);
        }


        function setItem(item, left, top, scale, opacity) {
            item.style.transform = `translate(${left}px, ${top}px) scale(${scale})`;
            item.style.opacity = opacity;
        }


        function setupItems() {
            items = document.querySelectorAll('.comet');
            items.forEach((item, i) => {
                const left = centerX + parseInt(item.dataset.left);
                const top = centerY + parseInt(item.dataset.top);
                const scale = parseFloat(item.dataset.scale);
                const opacity = parseFloat(item.dataset.opacity);
                setItem(
                    item, 
                    left, 
                    top, 
                    scale, 
                    opacity
                );

            });
        }

        function move(e) {
            let x = e.clientX;
            let y = e.clientY;
            let distanceX = x - centerX;
            let distanceY = y - centerY;

            items.forEach((item, i) => {
                const ratio = parseFloat(item.dataset.ratio);
                const left = parseInt(item.dataset.left);
                const top = parseInt(item.dataset.top);
                const x = centerX + left + (distanceX * ratio);
                const y = centerY + top + (distanceY * ratio);
                const scale = parseFloat(item.dataset.scale) 
                    + parseFloat(item.dataset.scale) * ((Math.abs(distanceX) + Math.abs(distanceY)) / 4780);
                const opacity = item.dataset.opacity;
                setItem(
                    item, 
                    x, 
                    y, 
                    scale, 
                    opacity
                );
            });
        }
    }
    return (
        <div className={container}>
            <div id="comets" className={comets}>
                <div className={`${comet} comet`} 
                    data-left="-30"
                    data-top="0"
                    data-ratio="0.21"
                    data-scale="1"
                    data-opacity="1"
                    ><img src="/images/kometa_3.png" alt=""/>
                </div>

                <div className={`${comet} comet`} 
                    data-left="643"
                    data-top="-214"
                    data-ratio="0.11237"
                    data-scale="0.51524"
                    data-opacity="1"
                    ><img src="/images/kometa_3.png" alt=""/>
                </div>

                <div className={photo}>
                    <img src="/images/about/about_photo.png" alt=""/>
                </div>

                <div className={`${comet} comet`} 
                    data-left="533"
                    data-top="84"
                    data-ratio="0.31237"
                    data-scale="2.880524"
                    data-opacity="1"
                    style={{zIndex: '3'}}
                    ><img src="/images/kometa_3.png" alt=""/>
                </div>

            </div>
        </div>
    )
}

export default Comets
